import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';
import LazyLoad from 'react-lazyload';

import Blurb from './Blurb';

const InfoBlockStyled = styled.section`
    position: relative;
    display: flex;
    flex-direction: ${props =>
        props.imageAlignment == 'left' ? 'row-reverse' : 'row'};
    align-items: center;
    justify-content: space-between;
    padding: 0 90px;
    z-index: 1;

    .blurb,
    img {
        margin: 0 20px;
    }

    .blurb {
        width: 48%;

        @media screen and (max-width: 1024px) {
            width: 85%;
        }

        @media screen and (max-width: 768px) {
            margin-top: -172px;
            padding: 53px 30px;
            background-color: #fff;
            z-index: 100;
            box-sizing: border-box;
        }
    }

    img {
        max-width: 48%;
        height: auto;

        @media screen and (max-width: 1024px) {
            max-width: 100%;
            width: initial;
            margin-bottom: 40px;
            height: auto;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
            height: auto;
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        align-items: center;
        width: 90%;
        background-color: #fff;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const InfoBlock = ({
    buttonText,
    copy,
    imageAlignment,
    imageAlt,
    imageSource,
    link,
    linkIsExternal,
    mobileImageSource,
    size,
    textAlignment,
    title,
}) => {
    return (
        <InfoBlockStyled className="infoBlock" imageAlignment={imageAlignment}>
            <Blurb
                buttonText={buttonText}
                copy={copy}
                link={link}
                linkIsExternal={linkIsExternal}
                textAlignment={textAlignment}
                title={title}
            />
            <img
                src={size.width < 769 ? mobileImageSource : imageSource}
                alt={imageAlt}
            />
        </InfoBlockStyled>
    );
};

InfoBlock.propTypes = {
    buttonText: PropTypes.string,
    copy: PropTypes.string.isRequired,
    imageAlignment: PropTypes.string,
    imageAlt: PropTypes.string.isRequired,
    imageSource: PropTypes.node.isRequired,
    link: PropTypes.string,
    linkIsExternal: PropTypes.bool,
    mobileImageSource: PropTypes.node.isRequired,
    textAlignment: PropTypes.string,
    title: PropTypes.string.isRequired,
};

InfoBlock.defaultProps = {
    imageAlignment: 'right',
    linkIsExternal: false,
    textAlignment: 'left',
};

export default withSize()(InfoBlock);
