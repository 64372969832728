import styled from 'styled-components';

const ServicesStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;

    h2 {
        margin-top: 70px;
    }

    .infoBlock {
        margin: 90px 0;

        @media screen and (max-width: 768px) {
            padding: 0;

            img {
                margin: 0;
            }
        }
    }

    .section-divider {
        width: 80%;
        height: 15px;
        background-color: #f7f7f5;
    }

    .our-products {
        width: 575px;
        padding: 90px 0;
    }

    .product-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1062px;
        list-style: none;
        margin-top: 0;
        margin-bottom: 90px;
        padding: 0;
        font: 600 18px sofia-pro, sans-serif;
        color: #4a4f53;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 33.33%;
            max-width: 233px;
            padding: 60px;
            text-align: center;
            border-right: 1px solid #979797;
            border-bottom: 1px solid #979797;

            &:nth-child(3n) {
                border-right: none;
            }

            &:nth-child(n + 4):nth-child(-n + 6) {
                border-bottom: none;
            }

            @media screen and (max-width: 1060px) {
                max-width: 300px;
                padding: 60px 3vw;

                &:nth-child(1n) {
                    border-right: 1px solid #979797;
                }

                &:nth-child(2n) {
                    border-right: none;
                }

                &:nth-child(4) {
                    border-bottom: 1px solid #979797 !important;
                }
            }

            @media screen and (max-width: 768px) {
                width: 85%;
                max-width: 700px !important;
                border-right: none !important;
                border-bottom: 1px solid #979797 !important;

                &:last-child {
                    border-bottom: none !important;
                }
            }
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 65px;
        }
    }

    .safety {
        width: 740px;
        padding: 90px 0;
    }

    .contact-form__comp {
        width: 100%;
    }

    .our-products,
    .safety {
        @media screen and (max-width: 768px) {
            width: 80%;
        }
    }
`;

export default ServicesStyled;
