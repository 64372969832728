import React from 'react';
import { withSize } from 'react-sizeme';
import PropTypes from 'prop-types';

import SEO from '../components/SEO';

import ServicesStyled from '../components/page-styles/services-styles';

import Blurb from '../components/Blurb';
import ContactForm from '../components/ContactForm/ContactForm';
import Heading from '../components/Heading';
import InfoBlock from '../components/InfoBlock';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';

import DEFImage from '../images/def-desktop.png';
import DEFMobileImage from '../images/def-mobile.png';
import DeliveredWholesaleImage from '../images/delivered-wholesale-desktop.png';
import DeliveredWholesaleMobileImage from '../images/delivered-wholesale-mobile.png';
import RackMarketingImage from '../images/rack-marketing-desktop.png';
import RackMarketingMobileImage from '../images/rack-marketing-mobile.png';
import ServicesHeader from '../images/services-hero-large.png';

const ServicesPage = ({ path, size }) => (
    <Layout path={path}>
        <ServicesStyled className="services">
            <SEO title="Services" />
            <SubPageHeader
                bgImage={ServicesHeader}
                text="We're there, whenever and wherever you need us."
            />
            <Heading headingType="h2" color="#323333" text="Our Services" />
            <span className="section-divider"></span>
            <InfoBlock
                copy="Saratoga sells fuel directly from the terminal at facilities across the United States, including several that we own and operate. This wide-spanning network offers you unparalleled freedom, whether you’re picking up product yourself or arranging for delivery via a third party."
                imageAlt="Several fuel trucks fueling up at a rack location."
                imageSource={RackMarketingImage}
                mobileImageSource={RackMarketingMobileImage}
                title="Rack Marketing"
                size={size}
            />
            <span className="section-divider"></span>
            <InfoBlock
                copy="Saratoga’s 24/7 dispatch and extensive carrier network act as a rolling pipeline that can deliver product where and when you need it across Canada and the lower 48 states."
                imageAlignment="left"
                imageAlt="Several fuel trucks fueling up at a rack location."
                imageSource={DeliveredWholesaleImage}
                mobileImageSource={DeliveredWholesaleMobileImage}
                title="Fuel Delivery"
                size={size}
            />
            <span className="section-divider"></span>
            <InfoBlock
                copy="Saratoga is one of the nation’s premier providers of diesel exhaust fluid. We sell our DEF in bulk alongside other products, as well as in small containers available in the retail marketplace nationwide. As a recent inclusion in many federal regulations, a reliable DEF supplier is an essential partner for any modern fleet."
                imageAlt="Several fuel trucks fueling up at a rack location."
                imageSource={DEFImage}
                mobileImageSource={DEFMobileImage}
                title="DEF"
                size={size}
            />
            <span className="section-divider"></span>
            <Blurb
                className="our-products"
                copy="Saratoga combines award winning, market-leading products with industry-standard technology and services to provide unprecedented agility and real-time risk assessment."
                textAlignment="center"
                title="Our Products"
            />
            <ul className="product-grid">
                <li>On Road Diesel</li>
                <li>Off Road Diesel</li>
                <li>Bio Diesel - Varying Blends</li>
                <li>DEF</li>
                <li>Gasoline (+/- Ethanol)</li>
                <li>Cost-efficient Fuel Additive Programs</li>
            </ul>
            <span className="section-divider"></span>
            <Blurb
                className="safety"
                copy="The safety and security of our team members, business partners, communities, and assets is our top priority. Our team members are highly trained and prepared for emergency situations relating to our operations."
                textAlignment="center"
                title="Commitment to Safety"
            />
            <ContactForm id="contact" />
        </ServicesStyled>
    </Layout>
);

ServicesPage.propTypes = {
    size: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default withSize()(ServicesPage);
