import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Heading from './Heading';

const HeaderStyled = styled.div`
    margin-top: 80px;
    background: url(${props => props.bgImage}) no-repeat center;
    background-size: cover;
    width: 100vw;
    height: 550px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        width: 80%;
    }
    @media screen and (min-width: 1400px) {
        height: 650px;
        h1 {
            width: 60%;
        }
    }
`;

function SubPageHeader({ text, bgImage, color }) {
    return (
        <HeaderStyled bgImage={bgImage}>
            <Heading headingType="h1" text={text} color={color}></Heading>
        </HeaderStyled>
    );
}

SubPageHeader.propTypes = {
    text: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    color: PropTypes.string,
};

SubPageHeader.defaultProps = {
    color: '#ffffff',
};

export default SubPageHeader;
