import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from './Button';

const BlurbStyled = styled.section`
    display: flex;
    flex-direction: column;
    align-items: ${props =>
        props.textAlignment == 'center' ? 'center' : 'flex-start'};
    text-align: ${props =>
        props.textAlignment == 'center' ? 'center' : 'flex-start'};

    header {
        font: 900 42px sofia-pro, sans-serif;
        letter-spacing: -1.52px;
        line-height: 48px;
    }

    p {
        color: #4a4f53;
        font-size: 18px;
        line-height: 36px;
    }
`;

const Blurb = ({
    buttonText,
    className,
    copy,
    link,
    linkIsExternal,
    textAlignment,
    title,
}) => {
    return (
        <BlurbStyled
            className={`blurb ${className ? className : ''}`}
            textAlignment={textAlignment}
        >
            <header>{title}</header>
            <p>{copy}</p>
            {link ? (
                <Button
                    buttonText={buttonText}
                    buttonType="primary"
                    link={link}
                    linkIsExternal={linkIsExternal}
                />
            ) : null}
        </BlurbStyled>
    );
};

Blurb.propTypes = {
    buttonText: PropTypes.string,
    copy: PropTypes.string.isRequired,
    link: PropTypes.string,
    linkIsExternal: PropTypes.bool,
    textAlignment: PropTypes.string,
    title: PropTypes.string.isRequired,
};

Blurb.defaultProps = {
    linkIsExternal: false,
    textAlignment: 'left',
};

export default Blurb;
